import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { MdLocationPin, MdRoofing } from "react-icons/md";

function Directories() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="px-4 py-8">
        {/* <div className="flex justify-center items-center pb-5">
          {
            rpdata?.gmb?.link.length > 1 ?
              <a href={rpdata?.gmb?.link} target="_blank" rel='noopener noreferrer'>
                <img src={rpdata?.gmb?.img} alt="Not found" width={'250px'} />
              </a>
              : null
          }
        </div> */}
        {
          rpdata?.dbSocialMedia?.redes.length > 0 || rpdata?.dbSocialMedia?.directorios.length > 0 ?
            <h2 className="text-center pb-5">
              Find Us On
            </h2>
            : null
        }
        {/* <div className={`grid ${rpdata?.dbSocialMedia?.directorios?.length > 3 || rpdata?.dbSocialMedia?.redes?.length > 3 ? 'md:grid-cols-4' : 'md:grid-flow-col' } grid-cols-1 gap-4 bg-auto md:bg-contain bg-top-[100px]`}> */}
        <div className={`w-4/5 mx-auto flex flex-wrap justify-center items-center gap-4 pb-5`}>
          <div className="ml-3 md:flex flex-col justify-end items-center gap-3 w-full">
            <ul className="flex gap-2 flex-wrap ">
              {rpdata?.dbSocialMedia?.redes.slice(0, 8).map((item, index) => {
                return (
                  <li key={index} className="bgdora text-white w-[44px] flex justify-center rounded-xl">
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      <i className={`fab text-[22px] m-3 fa-${item.icon}`} aria-hidden="true" />
                    </a>
                  </li>
                );
              })}
              {rpdata?.dbSocialMedia?.redes.slice(8, 9).map((item, index) => {
                return (
                  <li key={index} className="bgdora text-white w-[44px] flex justify-center rounded-xl">
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      <span className=" w-full h-full flex justify-center items-center">
                        <MdLocationPin className="text-[28px] " />
                      </span>
                    </a>
                  </li>
                );
              })}
              {rpdata?.dbSocialMedia?.redes.slice(9, 10).map((item, index) => {
                return (
                  <li key={index} className="bgdora text-white w-[44px] flex justify-center rounded-xl">
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      <span className=" w-full h-full flex justify-center items-center">
                        <MdRoofing className="text-[28px] " />
                      </span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Directories;
