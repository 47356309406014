import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { MdLocationPin } from "react-icons/md";

function SocialMedia() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="wrapper">
        <ul className="social-icons icon-circle list-unstyled list-inline mt-6">
          {
            rpdata?.dbSocialMedia?.redes?.[0].url.length > 0 ?
              rpdata?.dbSocialMedia?.redes.slice(0, 8).map((item, index) => {
                return (
                  <li key={index}>
                    <a href={item.url} target="_blank" rel='noopener noreferrer'>
                      <i
                        className={`fab fa-${item.icon} wp-icon`}
                        aria-hidden="true"
                      />
                    </a>
                  </li>
                );
              })
              : null
          }
          {
            rpdata?.dbSocialMedia?.redes?.[0].url.length > 0 ?
              rpdata?.dbSocialMedia?.redes.slice(8, 9).map((item, index) => {
                return (
                  <li key={index}>
                    <a href={item.url} target="_blank" rel='noopener noreferrer'>
                      <span className=" w-full h-full flex justify-center items-center bg1 p-2 rounded-full ">
                        <MdLocationPin className="text-[24px] " />
                      </span>
                    </a>
                  </li>
                );
              })
              : null
          }

        </ul>
      </div>
    </>
  );
}

export default SocialMedia;
