import { ButtonContent } from "../global/boton/ButtonContent";
import imgDefault from '../../assets/image/placeholder.png'
import { FiPhoneCall } from "react-icons/fi";
import { GlobalDataContext } from "../../context/context";
import { useContext } from "react";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts, image }) {
  const { rpdata } = useContext(GlobalDataContext);
  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]
  return (
    <>
      <div className="pic-wrapper svgHerosection1  w-full h-screen" >
        {
          BgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        <div className="z-[4] relative w-4/5 mx-auto h-full grid grid-flow-col-1 justify-center items-center md:pb-[140px] md:pt-[450px] pt-[300px] pb-[100px]">
          <div>
            <div className="w-full flex flex-col justify-center items-center ">
              <h1 className="text-white text-center text-[35px] md:text-[50px]">
                {title}
              </h1>
              <p className="text-white text-center">
                {texts}
              </p>
            </div>
            <div className="w-full flex justify-center py-5">
              <ButtonContent btnStyle={'three'} />
            </div>
          </div>


          {/* <div className="flex justify-center items-start md:-mr-32">
            <img className="md:w-[320px] w-[170px]" src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Años%20de%20experiencia%2F10%20Years.png?alt=media&token=4a8697b6-cc1e-42e8-9577-7d780cfd2fb8" alt="experience years" />
          </div> */}

        </div>
      </div>
      <div className="w-full h-auto flex justify-center">
        <div className=" flex md:flex-row flex-col md:w-[70%] w-[100%] bgdora text-white md:-mt-20 -mb-12 -mt-20 relative">
          <div className="  w-full md:w-3/5 bg-cover bg-center h-[240px]" style={{ backgroundImage: `url("${image ? image : imgDefault}")` }}>
          </div>
          <div className="w-full md:w-2/5 h-full flex flex-col items-center justify-center">
            <FiPhoneCall className="md:text-[35px] text-[20px]" />
            <h2 className="md:text-[16px] text-[20px] font-bold mt-3 text-white ">Call us</h2>
            {rpdata?.dbPrincipal?.phones.slice(0, 3)?.map((item, index) => {
              return (
                <ul key={index}>
                  <li className="md:py-1 py-4 flex">
                    <a
                      href={`tel:+1${item.phone}`}
                      className="md:mx-6 mx-2 items-center flex"
                    >
                      <span className="flex flex-col md:pt-[5px]">
                        <span className="md:pl-3 font-bold md:text-[22px] text-[14px]">
                          {item.phone}
                        </span>
                        {rpdata?.dbPrincipal?.phones?.[0].name.length > 0 ? (
                          <span className="md:pl-3 mt-[-5px]">{item.name}</span>
                        ) :
                          null
                        }

                      </span>
                    </a>
                  </li>
                </ul>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
